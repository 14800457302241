<template>
  <div class="flex items-center" style="gap: 8px">
    <p class="text" :style="`font-size: ${labelSize}`">{{ text }}</p>
    <p class="hint">{{ hint }}</p>
  </div>
</template>

<script>
export default {
  name: 'FormLabelWithHint',
  props: {
    text: { type: String, default: '' },
    hint: { type: String, default: '' },
    labelSize: { type: String, default: '14px' },
  },
}
</script>

<style lang="postcss" scoped>
.text {
  @apply font-medium text-gray-100;
}
.hint {
  @apply text-sm text-gray-60 font-medium ;
}
</style>
